import revive_payload_client_FbNX1bQB1a from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.16_eslint@9.19.0_rollup@4.32.1_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_x14au9qP6G from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.16_eslint@9.19.0_rollup@4.32.1_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NuzyRqZzTz from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.16_eslint@9.19.0_rollup@4.32.1_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_9D0OMHZmfb from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.32.1_vite@6.0.11_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_PRTYO8Aoyz from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.16_eslint@9.19.0_rollup@4.32.1_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_n95AvnEdCw from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.16_eslint@9.19.0_rollup@4.32.1_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_PleOUkjiar from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.16_eslint@9.19.0_rollup@4.32.1_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_CV35KSEKek from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.16_eslint@9.19.0_rollup@4.32.1_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/dotykacka/designex-group/dg-client-zone/.nuxt/components.plugin.mjs";
import prefetch_client_2cxtZt9g4G from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/nuxt@3.15.4_@types+node@20.17.16_eslint@9.19.0_rollup@4.32.1_typescript@5.1.3_vite@6.0.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/dotykacka/designex-group/dg-client-zone/.nuxt/pwa-icons-plugin.ts";
import pwa_client_Bc9Yxliu0v from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_rollup@4.32.1_vite@6.0.11_workbox-build@7.3.0_workbox-window@7.3.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import i18n_LvLTZlaV3X from "/builds/dotykacka/designex-group/dg-client-zone/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.32.1_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _01_sentry_client_2rh1pDAIty from "/builds/dotykacka/designex-group/dg-client-zone/plugins/01.sentry.client.ts";
import _02_firebase_client_4c9KdheLb8 from "/builds/dotykacka/designex-group/dg-client-zone/plugins/02.firebase.client.ts";
import _03_firebaseui_client_2DeIH0F1by from "/builds/dotykacka/designex-group/dg-client-zone/plugins/03.firebaseui.client.ts";
import _04_functions_client_8C4voHcNsM from "/builds/dotykacka/designex-group/dg-client-zone/plugins/04.functions.client.ts";
import _05_qrcode_client_nixZao7HuG from "/builds/dotykacka/designex-group/dg-client-zone/plugins/05.qrcode.client.ts";
import _06_vue_3_sanitize_client_YBWgHnW9Av from "/builds/dotykacka/designex-group/dg-client-zone/plugins/06.vue-3-sanitize.client.ts";
export default [
  revive_payload_client_FbNX1bQB1a,
  unhead_x14au9qP6G,
  router_NuzyRqZzTz,
  _0_siteConfig_9D0OMHZmfb,
  payload_client_PRTYO8Aoyz,
  navigation_repaint_client_n95AvnEdCw,
  check_outdated_build_client_PleOUkjiar,
  chunk_reload_client_CV35KSEKek,
  components_plugin_KR1HBZs4kY,
  prefetch_client_2cxtZt9g4G,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_Bc9Yxliu0v,
  i18n_LvLTZlaV3X,
  _01_sentry_client_2rh1pDAIty,
  _02_firebase_client_4c9KdheLb8,
  _03_firebaseui_client_2DeIH0F1by,
  _04_functions_client_8C4voHcNsM,
  _05_qrcode_client_nixZao7HuG,
  _06_vue_3_sanitize_client_YBWgHnW9Av
]